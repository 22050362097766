import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DOMPurify from 'isomorphic-dompurify';

import css from './FandomShopPanel.module.scss';
import { useSelector } from 'react-redux';
import { FaveFandom } from '../../../models/FaveFandom';

import { getFandom } from '../../../util/api';

interface Props {
  fandom: string;
}

const FandomShopPanel: React.FC<Props> = ({ fandom }) => {

  const [fandomy, setFandomy] = useState<FaveFandom>();
  useEffect(() => {
    async function fetchMyAPI() {
      let response = await getFandom(fandom);
      setFandomy(response.data);
      console.log(response.data);
    }

    fetchMyAPI();
  }, []);


  if (!fandomy || !fandomy.key) {
    return <div></div>;
  }

  if (fandomy) {
    return (
      <section className={css.root}>
       
        <div className={css.fandomHeroImageWrapper}>
          {/* <img
            className={css.fandomHeroImage}
            src={fandomy.marketplace.background_image_url}
            alt={'fandom background picture'}
          /> */}
        </div>
        <div className={css.fandomInfo}>
          <div className={css.fandomBanner}>{fandomy.name}</div>
          <div
            className={css.fandomText}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(fandomy.marketplace.text_html_v2, {
                ALLOWED_TAGS: ['div', 'span', 'b', 'q', 'i'],
                ALLOWED_ATTR: ['style'],
              }),
            }}
          />
        </div>
      </section>
    );
  }
};

export default FandomShopPanel;
